import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import styled from "styled-components";
import NavbarLogin from "./NavBarLogin"
import chefjpeg from "../assets/chef.jpeg"
const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);
  const handleSubmit = e => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;
    username.length > 0 && loginUser(username, password);
  };

  return (
    <Container>
      <NavbarLogin />

      <RowIcon>
        <ImgChef src={chefjpeg} alt="" />
      </RowIcon>
    <Row>
      <Col />
      <Col lg="8">
          <h3>
          </h3>
          <Card>
            <CardBody>
            <Section>
              <form className="formblock"  onSubmit={handleSubmit}>
                <Connexion>Connexion Administration</Connexion>
                <hr />
                <Label htmlFor="username">Utilisateur : </Label><br></br>
                <input className="username" type="text" id="username" placeholder="Nom d'utilisateur" /><br></br>
                <br></br>

                <Label htmlFor="password">Mot de passe : </Label><br></br>
                <input type="password" id="password" placeholder="Mot de passe" /><br></br>
                <br></br>
                <Button type="submit" color="success">
                  Connexion
                </Button>
              </form>
            </Section>
            </CardBody>
          </Card>
      </Col>
      <Col />
    </Row>
  </Container>
  );
};
const Section = styled.section`
  .formblock {
    text-align: center;
  }
`
const RowIcon = styled(Row)`
  justify-content: center;
`
const Label = styled.label`
  color: #ff063b;
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 3em;
  margin-bottom: 0;
`
const Connexion = styled.h1`
  color: #ff063b;
  font-family: Brush Script MT, Brush Script Std, cursive;
  font-size: 7em;
  margin-bottom: 0;
`

const ImgChef = styled.img`
  width: 15em;
`

export default LoginPage;
