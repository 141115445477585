import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AuthContext from "../context/AuthContext";

export default function NavbarLogin() {
  const [navbarState, setNavbarState] = useState(false);
  const { user, logoutUser } = useContext(AuthContext);
  const html = document.querySelector("html");
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Nav>
        <div className="brand1">
          <a href="/">
            <p className="social-name">La Petite Charrue</p>
            <p className="social-name-two">Auberge du Terroir</p>
          </a>
        </div>
      </Nav>
    </>
  );
}


const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 4vw;
  border-bottom: 2px solid #7a032a;
  .logout-button {
    margin-right: 25em;
  }
  a {
    text-decoration: none;
  }
  .brand1 {
    .social-name{
      color: #ff063b;
      font-family: Brush Script MT, Brush Script Std, cursive;
      font-size:23px;
      margin-bottom: 0;
    }
    .social-name-two{
      color: white;
      font-family: Brush Script MT, Brush Script Std, cursive;
      font-size: 18px;
    }
  }
`;
