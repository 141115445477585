import React from 'react';
  
const PageNotFound = () =>{
    return ( 
    <div>
        <h1>404 Error</h1>
        <h1>La page est introuvable.</h1>
    </div>
    )
 
}
  
export default PageNotFound;