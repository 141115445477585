import React from "react";
import {useState, useEffect, useContext} from 'react';
import styled from "styled-components";
import API from "../../../API";
import ModalArticle from "./Modal";
import { Button } from 'reactstrap';
import { imageZoomEffect, TitleStyles } from "../../../components/ReusableStyles";
import { BsArrowCounterclockwise, BsFillPencilFill } from "react-icons/bs";
import AuthContext from "../../../context/AuthContext";
import AlertDelete from "../../AlertDelete"

export default function Produits(props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalAddArticleShow, setModalAddArticleShow] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [articles, setArticles] = useState([])
  const [name , setName] = useState("");
  const [description , setDescription] = useState("");
  const [id , setId] = useState("");
  const [price , setPrice] = useState("");
  const [article_img , setImage_url] = useState();
  const [active , setActiveItem] = useState([]);
  const { user, logoutUser, authTokens } = useContext(AuthContext);

  useEffect(() => {
    refreshArticles();
  }, []);

  const updateAfterDelete = () => {
    refreshArticles();
  };

  const refreshArticles = () => {
    API.get(`/api/${props.service}/`)
      .then((res) => {
        setArticles(res.data);
      })
      .catch(console.error);
  };

  const onShowDelete = (item) => {
    setActiveItem(item);
    setModalDelete(true)
  }

  const onDelete = () => {
    console.log('authTokens =>',authTokens)
    API.delete(`/api/${props.service}/${active.id}/`)
    .then((res) => updateAfterDelete(active))
    .then((res) => refreshArticles())
    .catch(console.error);
    setModalDelete(false)
  };

  const onUpdate = (item) => {
    setActiveItem(item);
    setModalShow(true)  
  };

  const setModalAddArticle = (item) => {
    
    setActiveItem("");
    refreshArticles();
    setModalShow(true) ;
  };



  const handlesubmit = (item) => {
    if(item.id){
      let data = {
        id: item.id,
        name : name,
        description :description,
        price : price,
        article_img : article_img,
      }
      if(!name){
        data.name = item.name
      }
      if(!description){
        data.description = item.description
      }
      if(!price){
        data.price = item.price
      }
      if(!article_img){
        data.article_img = null
      }
      console.log(active)
      console.log(data)
      API.put(`/api/${props.service}/${item.id}/`, data, {
        headers: {
          'content-type': 'multipart/form-data',
          // 'Authorization': "JWT " + authTokens.access,
        }
      })
        .then((res) => refreshArticles())
        .catch(err=> console.log(err));
        setModalShow(false)  
        setName('')
        setDescription('')
        setPrice('')
        setImage_url('')
    
    }else { 
      let data = {
        name : name,
        description : description,
        price : price,
        article_img : article_img
      }

      API.post(`/api/${props.service}/` , data, {
        headers: {
          'content-type': 'multipart/form-data',
          // 'Authorization': "JWT " + authTokens.access,
        }
      })
        .then((res) => refreshArticles())
        .catch(err=> console.log(err));
        setModalShow(false)
    }

  }
  const nl2br = require('react-nl2br');
  return (
    <Section>
      <div className="title"> 
        <TitleGridProduct>
          {props.service === 'Fondues' ?(
            <>
              {props.title} 
              <TitleProduct>A partir de deux personnes, prix à la portion</TitleProduct>
            </>
          ) :(<>
            {props.title} 
          </>)}
        </TitleGridProduct>   
      </div>
      {articles.map((product) => {
      return (
        <div className="allproduct">
        <div className="products">      
            <div className="product">
            {product.article_img ? (
              <>
              <div className="image">
                <img style={{borderRadius:'2.5em'}} src={product.article_img} alt="" />
              </div>
              </>
            ) :(<>
            
            </>)}
              <div style={{paddingTop:'5px'}} className="product-infos">
                <TitleProduct>{product.name}</TitleProduct>
                <p>{nl2br(product.description)}</p>
                {product.price ? ( 
                  <>  
                  <h3> {product.price}€</h3>
                  </>
                   ) : (
                    <>
                      {/* <Link to="/login">Login</Link> */}
                      {/* <Link to="/register">Register</Link>  */}
                    </>
                  )}
            
              </div>
                {product.name ? (
                  <>
                    <AlertDelete
                      show={modalDelete}
                      onHide={() => setModalDelete(false)}
                      validateDelete={() => onDelete(product)}
                    />
                      {user ? (
                    <>
                      <div class="btnCrudProduct">
                        <span>
                          <ButtonEdit style={{backgroundColor: 'transparent', fontSize: '20px', color: '#fa7300'}} onClick={() => onUpdate(product)}>
                            <BsFillPencilFill />
                          </ButtonEdit>
                        </span>
                        <span className="btnDelete">
                          <ButtonDelete>
                            <Button color="danger"
                              onClick={() => onShowDelete(product)}> Supprimer
                            </Button>
                          </ButtonDelete>
                        </span>
                      </div>
                    </>
                    ) : (
                      <>
                        {/* <Link to="/login">Login</Link> */}
                        {/* <Link to="/register">Register</Link>  */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to="/login">Login</Link> */}
                    {/* <Link to="/register">Register</Link>  */}
                  </>
                )}
            </div>  
        <ModalArticle
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleSubmit={() => handlesubmit(active)}
          activeItem={active}
          onChangeName={(e)=>setName(e.target.value)}
          onChangeDescription={(e)=>setDescription(e.target.value)}
          onChangePrice={(e)=>setPrice(e.target.value)}
          onChangeImage={(e)=>setImage_url(e.target.files[0])}
          services={props.service} 
        />
        </div>
      </div>
        );
      })}
      {user ? (
        <>
        <div class="addArticle">
          <ButtonAdd
            className="btn btn-primary"
            onClick={() => setModalAddArticle()}
          >
            Ajouter un article
          </ButtonAdd>
        </div>
        </>
      ) : (
        <>
          {/* <Link to="/login">Login</Link> */}
          {/* <Link to="/register">Register</Link>  */}
        </>
      )} 
    </Section>
    
  );
}

const TitleProduct = styled.h2`
    color: #fc4958;
`

const TitleGridProduct = styled.h1`
    color: #fc4958;
    font-family: cursive;
`

const ButtonAdd = styled.button`
    margin-left: 3em;
    margin-bottom: 3em;
`

const ButtonEdit = styled.button`
    border: none;
    background-color: white;
    margin-left: 1em;
`

const ButtonDelete = styled.span`
    margin-right: 10em;
`

const Section = styled.section`
  ${TitleStyles};
  margin-top: 1em!important;
  p{
    color:white;
  }
  .addArticle{
    text-align: center;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    margin-top: 3rem;
    .product {
      justify-self: center;
      padding-bottom: 3%;
      flex-direction: initial;
      gap: 5.6rem;
      justify-content: center;
      align-items: center;
      .product-infos {
        text-align: center;
        max-width: 60em;
        min-width: 60em;
      }
      .btnCrudProduct{
        text-align: center;
        padding-left: 20em;
        .btnDelete{
          padding-left: 5em;
        }
      }
      h3 {
        color: #fc4958;
      }
      .plus{
        color:red;
      }
      p {
        // text-align: center;
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: 0.2rem;
      }
      ${imageZoomEffect};
      .image {
        text-align: center;
        max-height: 15rem;
        overflow: hidden;
        border-radius: 1rem;
        img {
          height: 15rem;
          width: 15rem;
          object-fit: cover;
        }
      }
      .see {
        border: none;
        padding: 1rem 4rem;
        font-size: 1.4rem;
        color: white;
        border-radius: 4rem;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        background-color: #ff063b;
        text-transform: uppercase;
        &:hover {
          background: #f9c74f;
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    section{
      margin-top: 2em!important;
    }
    .products {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      text-align: center!important;
    }
    .product {
      display: inline-block!important;
      padding-left: initial!important;
    }
    .product-infos{
      max-width: 40em!important;
      min-width: initial!important;
    }
    img{
      margin-bottom: 2em;
      border-radius: 2em!important;
    }
  }
  
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    section{
      margin-top: 2em!important;
    }
    .products {
      grid-template-columns: repeat(2, 1fr);
      text-align: center!important;
    }
    .product {
      display: inline-block!important;
      padding-left: initial!important;
    }
    .product-infos{
      max-width: 40em!important;
      min-width: initial!important;
    }
    img{
      margin-bottom: 2em;
      border-radius: 2em!important;
    }
  }
`