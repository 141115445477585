import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Form, FormGroup, Label, Col, Input, FormText, ModalBody, Button} from 'reactstrap';

export default function AlertDelete(props) {
  console.log(props)

  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
       
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Modal heading
            </Modal.Title>
        </Modal.Header>
        <ModalBody>
          Voulez-vous vraiment supprimer cette article ?
        </ModalBody>
          <Modal.Footer>
              <Button onClick={props.onHide}>Annuler</Button>
              <Button color="danger"
                    onClick={props.validateDelete}> Supprimer
                </Button>{' '}
              {/* <Button color="danger" onClick={props.validateDelete}>Supprimer</Button> */}
          </Modal.Footer>
      </Modal>


  );
}
// onClick={toggle}

