import axios from 'axios';

console.log('process.env.NODE_ENV ',process.env.NODE_ENV)
if(process.env.NODE_ENV === 'production'){
    console.log('production')
    var locationHostSite = "http://"+window.location.hostname+"/"
}else {
    console.log('dev')
    var locationHostSite = "http://"+window.location.hostname+":8010/"
} 

export default axios.create({
    
    baseURL: locationHostSite,
    headers: {
        'Accept':'application/json',
        'Content-Type':'application/json',
    }, 
})