import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import {useState, useEffect} from 'react';
import { Form, FormGroup, Label, Col, Input, FormText, ModalBody} from 'reactstrap';


export default function ModalArticle(props) {
    return (
        console.log(props.services),
        <Modal
            {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
        >
            <ModalBody>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Gestionnaire
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <FormGroup>
                        <Label for="titre">
                            Titre
                        </Label>
                        <Input
                            id="name"
                            name="name"
                            defaultValue={props.activeItem.name}
                            placeholder="Choisir Titre"
                            onChange={props.onChangeName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">
                            Description
                        </Label>
                        <Input
                            id="description"
                            name="description"
                            type="textarea"
                            defaultValue={props.activeItem.description}
                            placeholder="Choisir Description"
                            onChange={props.onChangeDescription}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="price">
                            Prix
                        </Label>
                        <Input
                            id="price"
                            name="price"
                            defaultValue={props.activeItem.price}
                            placeholder="Choisir Prix"
                            onChange={props.onChangePrice}
                        />
                    </FormGroup>
                    {props.services != "ThemesDays" ? (
                        <>
                        <FormGroup row>
                            <Label
                                for="file"
                                sm={2}
                                >
                                Image
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="image_url"
                                    type="file" 
                                    name="file"
                                    accept="image/jpeg,image/png,image/gif"
                                    onChange={props.onChangeImage}
                                />
                            </Col>
                        </FormGroup>
                        </>
                    ) : (
                        <>
                        
                        {/* <Link to="/login">Login</Link> */}
                        {/* <Link to="/register">Register</Link>  */}
                        </>
                    )}
                    
                </Form>
            </ModalBody>
            <Modal.Footer>
                <Button onClick={props.onHide}>Annuler</Button>
                <Button onClick={props.handleSubmit}>Sauvegarder</Button>
            </Modal.Footer>
        </Modal>
    );
}


const FormAlerte = styled.span`   
    font-size: 15px;
    color: red;
`