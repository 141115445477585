import { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import styled from "styled-components";

export default function Mentions() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    registerUser(username, password, password2);
  };

  return (
    <section>
        <br />
        <TitleMentions>
            Mentions légales
        </TitleMentions>  
        <br />
        <TitleInfos class="text-center">Dénomination et siège social :</TitleInfos> 
        <p class="text-center">
            La petite charrue 13 rue principale 90150 VAUTHIERMONT <br />
            Siret: 45295260900017 <br />
            RCS: Belfort B 452 952 609 <br />
        </p>

        <br />
        <TitleInfos class="text-center">Création-Conception du site :</TitleInfos> 
        <p class="text-center">
            Heidet Antoine
        </p>

        <br />
        <TitleInfos class="text-center">Récépissé de déclaration :</TitleInfos> 
        <p class="text-center">
            Lorsqu’un site web diffuse ou collecte des données à caractère personnel, il doit être déclaré à la CNIL. <br />
            Toutefois, les sites web mis en oeuvre par des particuliers dans le cadre d’une activité exclusivement personnelle sont dispensés de déclaration. <br />
            A l’inverse, la diffusion et la collecte de données à caractère personnel à partir d’un site web dans le cadre d’activités professionnelles, <br />
            politiques ou associatives restent soumises à l’accomplissement des formalités prévues par la loi. <br />
        </p>

        <br />
        <TitleInfos class="text-center">Mention relative à la rectification et à la suppression des données nominatives :</TitleInfos> 
        <p class="text-center">
            En application de la Loi n° 78-17 du 6 janvier 1978 relative à l'Informatique, aux Fichiers et aux Libertés, vous disposez des droits d'opposition (art. 26 de la loi), <br />
            d'accès (art.34 à 38 de la loi) et de rectification (art. 36 de la loi) des données vous concernant. Ainsi, vous pouvez exiger que soient rectifiées, complétées, <br />
            mises à jour ou effacées les informations vous concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont la collecte ou l'utilisation, <br />
            la communication ou la conservation est interdite. <br />
        </p>

        <br />
        <TitleInfos class="text-center">Hébergement :</TitleInfos> 
        <p class="text-center">
            <OvhTitle class="text-center">OVH</OvhTitle>  <br />
            RCS Lille Métropole 424 761 419 00045 <br />
            Code APE 2620Z <br />
            N° TVA : FR 22 424 761 419 <br />
            Siège social : 2 rue Kellermann - 59100 Roubaix - France <br />

            En application de la loi du 11 mars 1957 (art. 41) et du code de la propriété intellectuelle du 1er juillet 1992, <br />
            toute reproduction partielle ou totale à usage collectif est strictement interdite sans autorisation de l'éditeur du présent site. <br />
            Les logos, visuels et marques présents sur ce site sont la propriété de leur détenteur respectif. <br />
            Ce site peut, à son insu, avoir été relié à d'autres sites par le biais de liens hypertextes. <br />
            L'éditeur, ainsi que la Société La petite charrue déclinent toute responsabilité pour les informations présentées sur ces autres sites. <br />
            L'internaute reconnaît que l'utilisation du présent site est régie par le droit français. <br />
        </p>

        <br />
    </section>
  );
}


const TitleMentions = styled.h1`
    color: #ff063b;
    text-align: center;
    font-family: Brush Script MT,Brush Script Std,cursive;
`

const TitleInfos = styled.h3`
    color: #ff063b;
    text-align: center;
    font-family: Brush Script MT,Brush Script Std,cursive;
`

const OvhTitle = styled.h2`
    color: #000e9c;
    text-align: center;
    font-family: Brush Script MT,Brush Script Std,cursive;
`