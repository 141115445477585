import React from "react";
import {useState, useEffect, useContext} from 'react';
import styled from "styled-components";
import { Button } from 'reactstrap';
import { imageZoomEffect, TitleStyles } from "../../components/ReusableStyles";
import { BsArrowCounterclockwise, BsFillPencilFill } from "react-icons/bs";
import Produits from "../Carte/Produits/Produits";


export default function ThemesDays(props) {

  // const fakeDB = [
  //   {id: 1, name: 'Menu Saint Valentin(midi et soir)', description: 'Le soir dîner aux chandelles \r\n Coupe fougerollaise et sa mise en bouche Assiette de foie gras \r\n Soufflé au saumon \r\n Filet de veau aux morilles et son accompagnement \r\n fromages comtois', price:'13.00'},
  //   {id: 2, name: 'Le samedi 23 février 2019:midi et soir:Moules frites à volonté', description: 'Salade franc-comtoise en entrée , moules à volonté (marinières,curry,à l\'ancienne,au bleu de Gex)', price:'13.00'}
  // ];

  return (
      <Section>
        <div className="title"> 
          <TitleGridProduct>
            {props.title}
          </TitleGridProduct>   
        </div>
        <Products service="ThemesDays"/>
      </Section>
    );
}


const TitleGridProduct = styled.h1`
    color: #fc4958;
    font-family: Brush Script MT,Brush Script Std,cursive;
`

const Products = styled(Produits)`
${TitleStyles};
.allproduct:first-child{
  display: none;
}
margin-top: 1em!important;
.products {
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;
  margin-top: 3rem;
  .product {
    text-align: center;
    padding-bottom: 3%;
    flex-direction: initial;
    gap: 5.6rem;
    justify-content: center;
    align-items: center;
    .product-infos {
      max-width: 60em;
      min-width: 60em;
    }
 
    h3 {
      color: #fc4958;
    }
    .plus{
      color:red;
    }
    p {
      // text-align: center;
      font-size: 1.1rem;
      line-height: 2rem;
      letter-spacing: 0.2rem;
    }
    ${imageZoomEffect};
    .image {
      max-height: 15rem;
      overflow: hidden;
      border-radius: 1rem;
      img {
        height: 15rem;
        width: 15rem;
        object-fit: cover;
      }
    }
    .see {
      border: none;
      padding: 1rem 4rem;
      font-size: 1.4rem;
      color: white;
      border-radius: 4rem;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      background-color: #ff063b;
      text-transform: uppercase;
      &:hover {
        background: #f9c74f;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 720px) {
  section{
    margin-top: 2em!important;
  }
  .products {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    text-align: center!important;
  }
  .product {
    display: inline-block!important;
    padding-left: initial!important;
  }
  .product-infos{
    max-width: 40em!important;
    min-width: initial!important;
  }
  img{
    margin-bottom: 2em;
    border-radius: 2em!important;
  }
}

@media screen and (min-width: 720px) and (max-width: 1080px) {
  section{
    margin-top: 2em!important;
  }
  .products {
    grid-template-columns: repeat(2, 1fr);
    text-align: center!important;
  }
  .product {
    display: inline-block!important;
    padding-left: initial!important;
  }
  .product-infos{
    max-width: 40em!important;
    min-width: initial!important;
  }
  img{
    margin-bottom: 2em;
    border-radius: 2em!important;
  }
}
`

const Section = styled.section`
  ${TitleStyles};
  margin-top: 1em!important;
  .products {
    justify-items: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    margin-top: 3rem;
    .product {
      text-align: center;
      padding-bottom: 3%;
      flex-direction: initial;
      gap: 5.6rem;
      justify-content: center;
      align-items: center;
      .product-infos {
        max-width: 60em;
        min-width: 60em;
      }
   
      h3 {
        color: #fc4958;
      }
      .plus{
        color:red;
      }
      p {
        // text-align: center;
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: 0.2rem;
      }
      ${imageZoomEffect};
      .image {
        max-height: 15rem;
        overflow: hidden;
        border-radius: 1rem;
        img {
          height: 15rem;
          width: 15rem;
          object-fit: cover;
        }
      }
      .see {
        border: none;
        padding: 1rem 4rem;
        font-size: 1.4rem;
        color: white;
        border-radius: 4rem;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        background-color: #ff063b;
        text-transform: uppercase;
        &:hover {
          background: #f9c74f;
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    section{
      margin-top: 2em!important;
    }
    .products {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      text-align: center!important;
    }
    .product {
      display: inline-block!important;
      padding-left: initial!important;
    }
    .product-infos{
      max-width: 40em!important;
      min-width: initial!important;
    }
    img{
      margin-bottom: 2em;
      border-radius: 2em!important;
    }
  }
  
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    section{
      margin-top: 2em!important;
    }
    .products {
      grid-template-columns: repeat(2, 1fr);
      text-align: center!important;
    }
    .product {
      display: inline-block!important;
      padding-left: initial!important;
    }
    .product-infos{
      max-width: 40em!important;
      min-width: initial!important;
    }
    img{
      margin-bottom: 2em;
      border-radius: 2em!important;
    }
  }
`