import React from "react";
import cloche from "../../assets/cloche1.png";
import {useState, useEffect, useContext} from 'react';
import styled from "styled-components";
import { IoMdRestaurant } from "react-icons/io";
import { GoCalendar } from "react-icons/go";
import { TitleStyles } from "../../components/ReusableStyles";
import API from "../../API";
import ModalHeaderServices from "./Modal";
import { BsFillPencilFill } from "react-icons/bs";
import AuthContext from "../../context/AuthContext";

export default function Service() {
  const [modalShow, setModalShow] = useState(false);
  const [services, setServices] = useState([])
  const { user, logoutUser } = useContext(AuthContext);

  useEffect(() => {
    refreshService();
  }, []);

  const refreshService = () => {
    API.get("/api/Service/")
      .then((res) => {
        setServices(res.data);
      })
      .catch(console.error);
  };

  const handleMessageChange = event => {

    console.log(services[0].content1)


    if(event.target.id === 'content1'){
      services[0].content1 = event.target.value
    }
    if(event.target.id === 'content2'){
      services[0].content2 = event.target.value
    }
    if(event.target.id === 'content3'){
      services[0].content3 = event.target.value
    }
  };

  const handleSubmit = () => {
    if(services[0].id) {

      API.put(`api/Service/${services[0].id}/`, services[0])
       .then((res) => refreshService());
       setModalShow(false)
      return;
    }
    API.post(`api/Service/`, services)
      .then((res) => refreshService());
      setModalShow(false)
  };


  return (
    <Section>
    {services.map((service, index) => {
        return(
          <div className="services">
            
            <div className="service">
              <TraiteurStyle />
              <p>
                {service.content1}
              </p>
            </div>
            {/* <div className="service">
              <img src={cloche} alt="" />
              <p>
                {service.content2}
              </p>
            </div> */}
            <div className="service">
              <EventFood />
              <p>
                {service.content3}
                <span>
                  {user ? (
                    <>
                    <ButtonEdit style={{backgroundColor: 'transparent', fontSize: '20px', color: '#fa7300'}} onClick={() => setModalShow(true)}>
                      <BsFillPencilFill />
                    </ButtonEdit>
                    </>
                  ) : (
                    <>
                      {/* <Link to="/login">Login</Link> */}
                      {/* <Link to="/register">Register</Link>  */}
                    </>
                  )}
                </span>
              </p>
            </div>
            <ModalHeaderServices
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleSubmit = {() => handleSubmit()}
                activeItem={services[0]}
                onChange={handleMessageChange}
              />
          </div>
        ); 
      })} 
    </Section>
  );  
}



const EventFood = styled(GoCalendar)`
  color: #fc4958;
  font-size: 3em;
`

const TraiteurStyle = styled(IoMdRestaurant)`
  color: #fc4958;
  font-size: 3em;
`

const ButtonEdit = styled.button`
    border: none;
    background-color: white;
    margin-left: 1em;
`

const Section = styled.section`
  margin: 2rem 4rem;
  ${TitleStyles};
  .services {
    color:white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10vw;
    margin-top: 4rem;
    .service {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5vw;
      padding: 0 3vw;
      img {
        height: 2.8rem;
      }
      p {
        text-align: center;
        line-height: 2rem;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
      }
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    margin: 2rem;
    .services {
      grid-template-columns: 1fr;
      color:white;
    }
  }
`;
