import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../../components/ReusableStyles";
import Image1 from "../../assets/Traiteur/1.png";
import Image2 from "../../assets/Traiteur/2.png";
import Image3 from "../../assets/Traiteur/3.png";
import Image4 from "../../assets/Traiteur/4.png";
import Image5 from "../../assets/Traiteur/5.png";
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const items = [
  {
    src: Image1,
    key: 1,
  },
  {
    src: Image2,
    key: 2,
  },
  {
    src: Image3,
    key: 3,
  },
  {
    src: Image4,
    key: 3,
  },
  {
    src: Image5,
    key: 3,
  },
];

export default function Traiteur(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <Item
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </Item>
      
    );
    
  });

  return (
    <Carouselvale
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <NextandPrevious
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <NextandPrevious
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
      
    </Carouselvale>
   
  );
  
}

const Carouselvale = styled(Carousel)`
@media screen and (min-width: 280px) and (max-width: 720px) {
  img{
    zoom: 0.4!important;
  } 
  .carousel-inner{
    height: 28em!important;
  }
}

@media screen and (min-width: 720px) and (max-width: 1080px) {
  img{
    zoom: 0.4!important;
  }
  .carousel-inner{
    height: 28em!important;
  }
}
`
const NextandPrevious = styled(CarouselControl)`
  color:black;
  .carousel-control-next-icon, .carousel-control-prev-icon{
    background-color: #400015!important;
    border-radius: 15px;
  }
`

const Item = styled(CarouselItem)`
  text-align: center!important;
  @media screen and (min-width: 280px) and (max-width: 720px) {
    img{
      zoom: 0.4!important;
    } 
    .carousel-inner{
      height: 28em!important;
    }
  }
  
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    img{
      zoom: 0.4!important;
    }
    .carousel-inner{
      height: 28em!important;
    }
  }

`


// zoom: 0.4;