import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState, useEffect} from 'react';
import styled from "styled-components";
import { Form, FormGroup, Label, Col, Input, FormText, ModalBody} from 'reactstrap';


export default function ModalHeaderServices(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalBody>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modification
                    </Modal.Title>
                </Modal.Header>
                {/* <Modal.Body> */}
                <Form>
                    <FormGroup>
                        <TextArea id="title" name="message" onChange={props.onChange}>
                            {props.activeItem.title}
                        </TextArea>
                    </FormGroup>
                    <FormGroup>
                        <TextArea  id="description" name="message" onChange={props.onChange}>
                            {props.activeItem.description}
                        </TextArea>
                    </FormGroup>
                </Form>
            </ModalBody>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Annuler</Button>
                    <Button onClick={props.handleSubmit}>Enregistrer</Button>
                </Modal.Footer>
           
        </Modal>
    );
}
const TextArea = styled.textarea`   
    min-width: 500px;
    min-height: 100px;
`