import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import { Form, FormGroup, Label, Col, Input, FormText, ModalBody} from 'reactstrap';




export default function ModalHeaderServices(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalBody>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modification
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <FormGroup>
                    <FormGroup>
                        <TextArea id="content1" name="message" onChange={props.onChange}>
                            {props.activeItem.content1}
                        </TextArea>
                    </FormGroup>
                    <FormGroup>
                        <TextArea id="content2" name="message" onChange={props.onChange}>
                            {props.activeItem.content2}
                        </TextArea>
                    </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <TextArea id="content3" name="message" onChange={props.onChange}>
                            {props.activeItem.content3}
                        </TextArea>
                    </FormGroup>
                </Form>
            </ModalBody>
            <Modal.Footer>
                <Button onClick={props.onHide}>Annuler</Button>
                <Button onClick={props.handleSubmit}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
}

const TextArea = styled.textarea`   
    min-width: 500px;
    min-height: 100px;
`

//min-width: 500px;
//min-height: 100px;