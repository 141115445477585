import React from "react";
import styled from "styled-components";
import logoQuali from "../assets/logoDQT.jpg";
import belfortTourisme from "../assets/Belfort_tourisme.jpg";
import { FaFacebookF } from "react-icons/fa";
import { FaEuroSign } from "react-icons/fa";
import { FaMoneyCheck } from "react-icons/fa";


export default function Footer() {
  return (
    <div className="footer">
      <Section id="footer">
        <div className="quality container">  
          <Reseau className="brand container">
            <ul>
              <li>
                <a target="_blank" href="https://fr-fr.facebook.com/lapetitecharrue90/">
                  <FaFacebookF /> 
                </a>
              </li>
            </ul>
          </Reseau>
          <ul>
            <li>
              <Qualit class="fit-picture" src={logoQuali} alt={logoQuali}/>
            </li>
            <br />
            <li>
              <Qualit class="fit-picture" src={belfortTourisme} alt={belfortTourisme}/>
            </li>
          </ul>
        </div>
        <div className="about container">
          <div className="title">
            <h3>Infos</h3>
          </div>
          <div>
            <p>La petite charrue <br></br>
            13 rue principale<br></br>
            90150 VAUTHIERMONT<br></br>
            Siret: 45295260900017<br></br>
            RCS: Belfort B 452 952 609</p>
          </div>
          <div>
            {/* <img src={avatar1} alt="" /> \ */}
            <a href="/mentions" className="active">
              Mentions Légales
            </a>
          </div>
        </div>
        <div className="horaire container">
          <div className="title">
            <h3>Horaires de l'échoppe</h3>
          </div>
          <ul>
            <li>De 7h à 12h et de 18h à 19h</li>
            <li>Fermé le lundi soir et le mercredi toute la journée</li>
          </ul>
        </div>
        <div className="horaire container">
          <div className="title">
            <h3>Horaires de service</h3>
          </div>
          <ul>
            <li>Lundi:	12:00–13:30, Fermé</li>
            <li>Mardi:	12:00–13:30, 19:00–21:15</li>
            <li>Mercredi:	Fermé</li>
            <li>Jeudi:	12:00–13:30, 19:00–21:15</li>
            <li>Vendredi:	12:00–13:30, 19:00–21:15</li>
            <li>Samedi:	12:00–13:30, 19:00–21:15</li>
            <li>Dimanche:	12:00–13:30, 19:00–21:15</li>

            <li>Service du midi, fermeture à 15h30</li>
            <li>Service du soir, fermeture à 23h30</li>

            <li>Dimanche:	12:00–13:30, 19:00–21:15</li>

          </ul>
        </div>
        <div className="contact container">
          <div className="title">
            <h3>Contact</h3>
          </div>
          <p><a href="tel:03 84 23 89 14">03 84 23 89 14</a><br></br>
            aubergelapetitecharrue@gmail.com<br></br>
            la petite charrue <br></br>
            13 rue principale<br></br>
            90150 VAUTHIERMONT
          </p>
        </div>
        <div className="paiement container">
          <div className="title_paiement">
            <Paiement>Moyens de paiement</Paiement>
          </div>
          <ul>
            <li>
              Espece
            </li>
            <li>
              Chèque
            </li>
            <li>
              Carte bleu
            </li>
            <li>
              Ticket restaurant
            </li>
            <li>
              ANCV
            </li>
          </ul>
        </div>
      </Section>
      <LowerFooter className="lower__footer">
        <h4>
          Copyright &copy; 2023 <Span>Heidet Antoine</Span>
        </h4>
      </LowerFooter>
    </div>
  );
}

const Paiement = styled.h3`
  padding-right: 2%;
`

const Qualit = styled.img`
  height: 40%;
  width: 50%;
`

const Span = styled.span`
  font-size: 13px;
  color: #fc4958;
`; 

const Section = styled.footer`
  margin: 0;
  background: linear-gradient(to right,#b42352,#400015);
  color: white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 2vw;
  gap: 2vw;
  p {
    font-size: 15px;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  ul {
    list-style-type: none;
    padding-left: initial;
    font-size: 15px;
  }
  .container {
    display: flex;
    width: 120%;
    flex-direction: column;
    gap: 0.5rem;
    h3 {
      font-size: 2rem;
    }
    .title_paiement { 
      display : inline-flex;
    }
    svg {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fc4958;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out;
      &:hover {
      }
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    grid-template-columns: 1fr;
    .container {
      img {
        height: 9rem;
        width: 15rem;
      }
    }
  }
`;
const Reseau = styled.div`
ul {
  display: flex;
  list-style-type: none;
  // gap: 4vw;
  // margin-top: 2vw;
  li {
    padding: 0.8rem;
    border-radius: 2rem;
    background-color: white;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      svg {
        transform: scale(1.2);
      }
      img {
        transform: scale(1.2);
      }
    }
    svg {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fc4958;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out;
      &:hover {
      }
    }
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fc4958;
      font-size: 1.6rem;
      transition: 0.3s ease-in-out;
      &:hover {
      }
    }
  }
}

}`;

const LowerFooter = styled.div`
  margin: 0;
  text-align: center;
  background-color: black;
  color: white;
  padding: 1rem;
  h2 {
    span {
      color: #fc4958;
      text-transform: uppercase;
    }
  }
  @media screen and (min-width: 260px) and (max-width: 450px) {
    h2 {
      span {
        display: block;
      }
    }
  }
`;
