import "./index.css";
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom'
import React, { useRef } from 'react';
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Produits from "./views/Carte/Produits/Produits";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./views/Services/Services";
import Service from "./views/Service/Service";
import Traiteur from "./views/Traiteur/Traiteur";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Login from "./views/loginPage";
import Register from "./views/registerPage";
import ProtectedPage from "./views/ProtectedPage";
import PageNotFound from "./views/Error/Error404"
import Mentions from "./views/Mentions"
import DaysTheme from "./views/DaysTheme/ThemeDays"
import Maps from "./views/Maps"
import Modal from 'react-modal';


const App = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const modalStyles = {
    content: {
      width: '60%', 
      margin: 'auto', 
      maxHeight: '80vh', 
      height:'80%', 
      borderRadius: '1em'
    },
  };

  return (
// ReactDOM.render(
  <Router>
    <div className="flex flex-col min-h-screen overflow-hidden">
      <AuthProvider>
        <Switch>
          <PrivateRoute component={ProtectedPage} path="/protected" />
          <Route component={Login} path="/login" />
          <Route component={Register} path="/register" />
          <Route exact path="/traiteur">
            <ScrollToTop />
            <Navbar />
            <Traiteur key="traiteur"/>
            <Footer />
          </Route>
          <Route exact path="/accueil/traiteur">
            <ScrollToTop />
            <Navbar />
            <Traiteur key="traiteur"/>
            <Footer />
          </Route>
          <Route exact path="/journées-a-themes">
            <ScrollToTop />
            <Navbar />
            <DaysTheme key="ThemesDays" title="LES JOURNÉES À THÈMES" service="ThemesDays"/>
            <Footer />
  
          </Route> 
          {/* <Route exact path="/accueil/la-carte">
            <ScrollToTop />
            <Navbar />
            <Hero />
            <Services />
            <Service />
            <Footer />
  
          </Route> */}
          <Route exact path="/plats-chauds">
            <ScrollToTop />
            <Navbar />
            <Produits key="plats-chauds" service="ArticlesChauds" title="LES SPÉCIALITÉS"/>
            <Produits key="specialite" service="Specialite" title="LES SPÉCIALITÉS"/>
            <Footer />
          </Route>
          <Route exact path="/les-menus">
            <ScrollToTop />
            <Navbar />
            <Produits key="menus" service="Menus" title="Les Menus"/>
            {/* <Produits service="Specialite" title="LES SPÉCIALITÉS"/> */}
            <Footer />
          </Route>
          <Route exact path="/les-viandes">
            <ScrollToTop />
            <Navbar />
            <Produits key="Viandes" service="Viandes" title="LES VIANDES"/>
            <Produits key="Poissons" service="Poissons" title="LES POISSONS"/>
            <Footer />
          </Route>
          <Route exact path="/les-fondues">
            <ScrollToTop />
            <Navbar />
            <Produits key="Fondues" service="Fondues" title="LES FONDUES AU FROMAGE"/>
            <Footer />
          </Route>
          <Route exact path="/entrees">
            <ScrollToTop />
            <Navbar />
            <Produits key="Entrees" service="Entrees" title="SALADES"/>
            <Produits key="EntreesFroides" service="EntreesFroides" title="ENTRÉES FROIDES"/>
            <Produits key="EntreesChaudes" service="EntreesChaudes" title="ENTRÉES CHAUDES"/>
            <Produits key="EntreesFoieGras" service="EntreesFoieGras" title="ENTRÉES FOIE GRAS"/>
            <Footer />
          </Route>
          <Route exact path="/desserts">
            <ScrollToTop />
            <Navbar />
            <Produits key="Desserts" service="Desserts" title="DESSERTS MAISON"/>
            <Produits key="CoupeGlaces" service="CoupeGlaces" title="COUPES GLACÉES"/>
            <Produits key="CoupeGlacesArrosees" service="CoupeGlacesArrosees" title="COUPES GLACÉES ARROSÉES"/>
            <Footer />
          </Route>
          <Route exact path="/vins">
            <ScrollToTop />
            <Navbar />
            <Produits service="Vins" title="VINS"/>
            <Footer />
          </Route>
          <Route exact path="/Mentions">
            <Navbar />
            <ScrollToTop />
            <Mentions />
            <Footer />
            {/* <Maps /> */}
          </Route>
          <Route exact path="/">
            <ScrollToTop />
            <Navbar />
            <Hero />
            <Services />
            <Service />
            <Footer />
            {/* <Maps /> */}
            <div>
              {/* ... Vos routes et autres éléments ... */}
              {/* ... */}

              {/* Modal pour la fermeture temporaire */}
              {new Date() >= new Date('2023-08-21') && new Date() <= new Date('2023-09-09') && (
                <Modal style={modalStyles} isOpen={modalIsOpen}>
                  <div style={{ textAlign:'center', justifyContent: 'center', paddingTop:'1em'}}>
                    <h2 style={{color: '#ff063b', fontSize:'2em'}}><p>Fermeture Temporaire</p></h2>
                    <strong style={{fontSize:'1.5em', paddingTop:'1em'}}><p>Le restaurant sera fermé du Lundi 28 Août à 15heures, au Vendredi 8 Septembre</p></strong>
                    <strong style={{fontSize:'1.5em',paddingTop:'1em'}}><p>Réouverture le Samedi 9 Septembre à Midi</p></strong>
                    <strong style={{fontSize:'1.5em', paddingTop:'1em'}}><p>Et du Lundi 11 Septembre, au Mercredi 13 Septembre</p></strong>
                    <strong style={{fontSize:'1.5em',paddingTop:'1em'}}><p>Réouverture le Jeudi 14 à Midi</p></strong>

                    <p style={{fontSize:'1.5em',paddingTop:'1em'}}>Merci de votre compréhension</p>
                    <p style={{color: '#ff063b', fontFamily: 'Brush Script MT,Brush Script Std,cursive', fontSize:'2em'}}>Votre restaurant La Petite Charrue</p>
                  
                    <button style={{backgroundColor: '#ff063b'}} className="btn btn-primary" onClick={() => closeModal()}>Fermer</button>
                  </div>
                </Modal>
              )}
            </div>
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </AuthProvider>
    </div>
</Router>
  );
};
  // document.getElementById("root")
  ReactDOM.render(<App />, document.getElementById('root'));
// );
